import * as React from "react"

let Cheer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path d="M12.9789 23.5001C18.7527 23.5001 23.4333 18.7838 23.4333 12.966C23.4333 7.14813 18.7527 2.43185 12.9789 2.43185C7.20503 2.43185 2.52441 7.14813 2.52441 12.966C2.52441 18.7838 7.20503 23.5001 12.9789 23.5001Z" fill="url(#paint0_radial_460_3514)" />
    <path opacity="0.5" d="M12.9789 23.5001C18.7527 23.5001 23.4333 18.7838 23.4333 12.966C23.4333 7.14813 18.7527 2.43185 12.9789 2.43185C7.20503 2.43185 2.52441 7.14813 2.52441 12.966C2.52441 18.7838 7.20503 23.5001 12.9789 23.5001Z" fill="url(#paint1_radial_460_3514)" />
    <path d="M10.6988 9.12076C10.7576 8.6994 10.3394 8.37679 9.56843 8.27804C8.90849 8.19245 7.42527 8.35704 6.25568 9.50921C6.04005 9.71989 6.30795 9.89107 6.50397 9.75939C7.18351 9.31828 8.90849 8.87058 10.163 9.18002C10.6727 9.31169 10.6988 9.12076 10.6988 9.12076Z" fill="url(#paint2_linear_460_3514)" />
    <path d="M15.2594 9.12076C15.2006 8.6994 15.6187 8.37679 16.3898 8.27804C17.0497 8.19245 18.5329 8.35704 19.7025 9.50921C19.9181 9.71989 19.6502 9.89107 19.4542 9.75939C18.7747 9.31828 17.0497 8.87058 15.7952 9.18002C15.279 9.31169 15.2594 9.12076 15.2594 9.12076Z" fill="url(#paint3_linear_460_3514)" />
    <path d="M12.9789 23.5001C18.7527 23.5001 23.4333 18.7838 23.4333 12.966C23.4333 7.14813 18.7527 2.43185 12.9789 2.43185C7.20503 2.43185 2.52441 7.14813 2.52441 12.966C2.52441 18.7838 7.20503 23.5001 12.9789 23.5001Z" fill="url(#paint4_radial_460_3514)" />
    <path opacity="0.5" d="M12.9789 23.5001C18.7527 23.5001 23.4333 18.7838 23.4333 12.966C23.4333 7.14813 18.7527 2.43185 12.9789 2.43185C7.20503 2.43185 2.52441 7.14813 2.52441 12.966C2.52441 18.7838 7.20503 23.5001 12.9789 23.5001Z" fill="url(#paint5_radial_460_3514)" />
    <path opacity="0.75" d="M12.9789 23.5001C18.7527 23.5001 23.4333 18.7838 23.4333 12.966C23.4333 7.14813 18.7527 2.43185 12.9789 2.43185C7.20503 2.43185 2.52441 7.14813 2.52441 12.966C2.52441 18.7838 7.20503 23.5001 12.9789 23.5001Z" fill="url(#paint6_radial_460_3514)" />
    <path opacity="0.4" d="M10.8752 20.3528C12.1923 20.3528 13.2601 19.2769 13.2601 17.9497C13.2601 16.6225 12.1923 15.5466 10.8752 15.5466C9.558 15.5466 8.49023 16.6225 8.49023 17.9497C8.49023 19.2769 9.558 20.3528 10.8752 20.3528Z" fill="url(#paint7_radial_460_3514)" />
    <path d="M13.1938 19.1743C13.5619 19.1743 13.8603 18.7852 13.8603 18.3052C13.8603 17.8252 13.5619 17.4362 13.1938 17.4362C12.8257 17.4362 12.5273 17.8252 12.5273 18.3052C12.5273 18.7852 12.8257 19.1743 13.1938 19.1743Z" fill="url(#paint8_linear_460_3514)" />
    <path d="M22.4791 14.941C22.5184 15.3031 22.2831 15.9484 21.9107 16.3697C21.6297 16.6923 21.2377 16.8174 20.8456 16.745C20.7672 16.7318 20.6954 16.7121 20.6169 16.6792C20.1138 16.4685 19.6368 16.0998 19.1272 16.1788L15.8928 16.6528C15.4616 16.7187 15.0434 16.8306 14.6383 16.9952L12.9395 17.884L13.0309 18.5029L14.9062 18.865C15.344 18.9111 15.7752 18.8979 16.2065 18.8387L20.2772 18.2395L21.4468 17.8577L22.9627 16.6989C23.309 16.4026 23.3024 15.7508 22.9496 15.4151L22.4791 14.941Z" fill="url(#paint9_linear_460_3514)" />
    <path d="M15.6448 16.6987C15.3051 16.7646 14.9718 16.8633 14.6451 17.0016L12.9463 17.8904L13.0378 18.5093L14.913 18.8714C15.2593 18.9043 15.6122 18.9043 15.9519 18.8714C15.7821 18.8385 15.586 18.3908 15.5076 17.8311C15.4292 17.2584 15.488 16.7777 15.6448 16.6987Z" fill="url(#paint10_linear_460_3514)" />
    <path d="M22.4791 14.941C22.5183 15.3031 22.2831 15.9484 21.9107 16.3697C21.6297 16.6923 21.2377 16.8174 20.8456 16.745C20.7672 16.7318 20.6954 16.7121 20.6169 16.6792C20.1138 16.4685 19.6368 16.0998 19.1272 16.1788L15.8928 16.6528C15.4616 16.7187 15.0434 16.8306 14.6383 16.9952L12.9395 17.884L13.0309 18.5029L14.9062 18.865C15.344 18.9111 15.7752 18.8979 16.2065 18.8387L20.0681 18.2724L21.205 18.0354L22.9561 16.7055C23.3024 16.4092 23.2959 15.7574 22.9431 15.4217L22.4791 14.941Z" fill="url(#paint11_linear_460_3514)" />
    <path d="M20.0742 18.2724C22.2501 17.9564 24.3997 17.4034 23.9358 14.5196C23.6026 12.426 20.4401 12.2351 20.2637 13.7033C20.4924 13.4531 20.8256 12.9461 21.0674 12.9395C22.1978 12.9 22.5441 14.2102 22.5637 14.9147C22.6029 16.6791 21.1523 18.1144 20.0742 18.2724Z" fill="url(#paint12_linear_460_3514)" />
    <path d="M22.1066 13.9798C22.4072 14.0786 22.4856 14.5592 22.479 14.9345C22.479 14.9345 22.564 15.1517 22.5052 15.2439L22.5509 15.1583C22.5509 15.1517 22.5574 14.9147 22.5574 14.9081C22.5378 14.2037 22.1785 12.8803 21.0611 12.933C20.0418 12.9857 20.1202 14.4736 20.4012 14.8884L20.558 15.1452L21.7668 14.9213C21.61 14.1181 21.8648 13.9008 22.1066 13.9798Z" fill="url(#paint13_linear_460_3514)" />
    <path d="M20.2314 14.2631C20.2837 14.6778 20.4536 15.0729 20.63 15.0465C20.8064 15.0202 20.8783 14.6186 20.8456 14.3816L22.3681 14.612C22.4007 14.8293 22.3354 15.198 22.1721 15.2243L20.6496 15.198C20.2968 15.1914 20.2314 14.4474 20.2314 14.2631Z" fill="url(#paint14_linear_460_3514)" />
    <path d="M13.2074 18.2791C13.0048 19.8197 11.7699 20.1554 12.0182 19.7143C12.3449 19.1152 12.4233 18.7202 12.4952 18.1869C12.567 17.6536 12.5932 17.252 12.4364 16.587C12.3122 16.0932 13.4099 16.7384 13.2074 18.2791Z" fill="#643800" />
    <path d="M12.4299 16.5869C12.5932 17.2518 12.5606 17.6535 12.4887 18.1867C12.4168 18.72 12.3384 19.1151 12.0117 19.7142C11.9267 19.8722 12.0182 19.9315 12.1881 19.8788C12.1816 19.8788 12.0182 19.8327 12.1685 19.6286C12.3972 19.3257 12.75 18.878 12.835 18.2394C12.9199 17.5942 12.6978 17.0741 12.554 16.7186C12.4691 16.4815 12.639 16.4815 12.639 16.4815C12.4952 16.3828 12.3907 16.4157 12.4299 16.5869Z" fill="url(#paint15_linear_460_3514)" />
    <path opacity="0.75" d="M22.4791 14.941C22.5183 15.3031 22.2831 15.9483 21.9107 16.3697C21.6297 16.6923 21.2377 16.8174 20.8456 16.7449C20.7672 16.7318 20.6954 16.712 20.6169 16.6791C20.1138 16.4684 19.6368 16.0997 19.1272 16.1787L15.8928 16.6528C15.4616 16.7186 15.0434 16.8305 14.6383 16.9951L12.9395 17.8839L13.0309 18.5028L14.9062 18.8649C15.344 18.911 15.7752 18.8978 16.2065 18.8386L20.0681 18.2724L21.205 18.0354L22.9561 16.7054C23.3024 16.4092 23.2959 15.7574 22.9431 15.4216L22.4791 14.941Z" fill="url(#paint16_radial_460_3514)" />
    <path opacity="0.75" d="M20.0742 18.2723C22.2501 17.9563 24.3997 17.4032 23.9358 14.5195C23.6026 12.4259 20.4401 12.2349 20.2637 13.7031C20.4924 13.4529 20.8256 12.946 21.0674 12.9394C22.1978 12.8999 22.5441 14.2101 22.5637 14.9146C22.6029 16.679 21.1523 18.1143 20.0742 18.2723Z" fill="url(#paint17_radial_460_3514)" />
    <path d="M8.25466 4.3542C8.35267 4.30811 8.3592 4.16985 8.26119 4.11718L7.30069 3.61023L1.55073 0.601423C1.43312 0.542169 0.96267 0.331487 0.433413 0.779186C-0.128514 1.27297 0.0021664 1.64825 0.0413706 1.76676L3.02743 9.55541C3.09277 9.73317 3.34106 9.72659 3.4064 9.54883C4.13821 7.45517 7.09813 4.89407 8.25466 4.3542Z" fill="url(#paint18_linear_460_3514)" />
    <path d="M7.30678 3.61035L6.11758 2.99147C5.68633 2.79395 2.06648 5.55257 2.15795 7.26436L2.85056 9.06833C2.02074 6.922 6.72525 3.30749 7.30678 3.61035Z" fill="url(#paint19_linear_460_3514)" />
    <path d="M4.90963 2.36578L4.64173 2.22094L3.72043 1.74032C3.33492 1.7074 1.17869 3.3731 1.09375 4.49894L1.66874 5.98688C1.53806 4.42651 4.51758 2.1551 4.90963 2.36578Z" fill="url(#paint20_linear_460_3514)" />
    <path d="M1.6225 0.647614C1.28273 0.772706 0.400637 1.45084 0.152344 2.04338L0.753475 3.61691C0.387569 2.65568 2.29551 1.20724 2.70062 1.20724L1.6225 0.647614Z" fill="url(#paint21_linear_460_3514)" />
    <path opacity="0.75" d="M8.25466 4.3542C8.35267 4.30811 8.3592 4.16985 8.26119 4.11718L7.30069 3.61023L1.55073 0.601423C1.43312 0.542169 0.96267 0.331487 0.433413 0.779186C-0.128514 1.27297 0.0021664 1.64825 0.0413706 1.76676L3.02743 9.55541C3.09277 9.73317 3.34106 9.72659 3.4064 9.54883C4.13821 7.45517 7.09813 4.89407 8.25466 4.3542Z" fill="url(#paint22_radial_460_3514)" />
    <path opacity="0.75" d="M8.25466 4.3542C8.35267 4.30811 8.3592 4.16985 8.26119 4.11718L7.30069 3.61023L1.55073 0.601423C1.43312 0.542169 0.96267 0.331487 0.433413 0.779186C-0.128514 1.27297 0.0021664 1.64825 0.0413706 1.76676L3.02743 9.55541C3.09277 9.73317 3.34106 9.72659 3.4064 9.54883C4.13821 7.45517 7.09813 4.89407 8.25466 4.3542Z" fill="url(#paint23_radial_460_3514)" />
    <path opacity="0.75" d="M8.25466 4.3542C8.35267 4.30811 8.3592 4.16985 8.26119 4.11718L7.30069 3.61023L1.55073 0.601423C1.43312 0.542169 0.96267 0.331487 0.433413 0.779186C-0.128514 1.27297 0.0021664 1.64825 0.0413706 1.76676L3.02743 9.55541C3.09277 9.73317 3.34106 9.72659 3.4064 9.54883C4.13821 7.45517 7.09813 4.89407 8.25466 4.3542Z" fill="url(#paint24_radial_460_3514)" />
    <path d="M10.1494 11.2673C9.4241 10.813 8.48974 10.8657 7.8102 11.3792C7.46389 11.6426 7.12412 12.0442 6.92157 12.6697C6.8889 12.775 6.86276 12.8869 6.85623 12.9989C6.84316 13.2227 6.99998 13.4268 7.24827 13.1898C9.18235 11.3529 10.5349 13.2425 10.5349 13.2425C10.7963 13.4334 11.0641 13.0384 11.0119 12.6697C10.9988 12.5643 10.9857 12.4524 10.9531 12.3537C10.8028 11.8204 10.4826 11.4846 10.1494 11.2673Z" fill="url(#paint25_radial_460_3514)" />
    <path d="M10.9532 12.3535C10.8813 12.1296 10.7899 11.9453 10.6919 11.7873C10.535 11.5634 10.3456 11.3988 10.1495 11.2737C9.42425 10.8194 8.48988 10.8721 7.81034 11.3857C7.46404 11.649 7.12427 12.0506 6.92172 12.6761C6.90211 12.7353 6.88904 12.8012 6.87598 12.867C6.87598 12.867 6.87598 12.867 6.87598 12.8736C7.7254 11.5371 8.9538 11.4449 8.9538 11.4449C8.9538 11.4449 10.1691 11.3396 10.9924 12.5378C10.9794 12.472 10.9728 12.4127 10.9532 12.3535Z" fill="url(#paint26_linear_460_3514)" />
    <path d="M15.8085 11.2673C16.5338 10.813 17.4682 10.8657 18.1477 11.3792C18.494 11.6426 18.8338 12.0442 19.0364 12.6697C19.069 12.775 19.0952 12.8869 19.1017 12.9989C19.1148 13.2227 18.9579 13.4268 18.7097 13.1898C16.7756 11.3529 15.423 13.2425 15.423 13.2425C15.1617 13.4334 14.8938 13.0384 14.946 12.6697C14.9591 12.5643 14.9722 12.4524 15.0049 12.3537C15.1486 11.8204 15.4688 11.4846 15.8085 11.2673Z" fill="url(#paint27_radial_460_3514)" />
    <path d="M14.9982 12.3535C15.0701 12.1296 15.1615 11.9453 15.2596 11.7873C15.4164 11.5634 15.6059 11.3988 15.8019 11.2737C16.5272 10.8194 17.4615 10.8721 18.1411 11.3857C18.4874 11.649 18.8271 12.0506 19.0297 12.6761C19.0493 12.7353 19.0624 12.8012 19.0754 12.867C19.0754 12.867 19.0754 12.867 19.0754 12.8736C18.226 11.5371 16.9976 11.4449 16.9976 11.4449C16.9976 11.4449 15.7823 11.3396 14.959 12.5378C14.9721 12.472 14.9851 12.4062 14.9982 12.3535Z" fill="url(#paint28_linear_460_3514)" />
    <path d="M15.4231 18.8913C15.2598 18.8452 15.0899 18.4173 15.0115 17.8906C14.9396 17.3902 14.9853 16.9557 15.1095 16.8372C14.9527 16.8832 14.7959 16.9425 14.6456 17.0018L14.626 17.0083C14.528 17.1532 14.4757 17.5153 14.5345 17.9498C14.5933 18.3712 14.7501 18.7201 14.8808 18.8584L14.9135 18.865C15.0834 18.8847 15.2532 18.8913 15.4231 18.8913Z" fill="url(#paint29_linear_460_3514)" />
    <g opacity="0.25">
      <path opacity="0.25" d="M4.66784 10.1547C5.00761 11.1423 4.15166 11.3991 4.43262 12.0377L5.38659 11.7875C5.10562 11.1489 5.8505 10.9053 5.54994 9.8848L4.66784 10.1547Z" fill="url(#paint30_radial_460_3514)" />
      <path opacity="0.25" d="M20.8132 6.96172C19.5063 7.70569 19.1731 6.17166 18.291 6.51402L18.5654 7.83736C19.441 7.50159 19.8134 8.93686 21.1203 8.19289L20.8132 6.96172Z" fill="url(#paint31_radial_460_3514)" />
      <path opacity="0.25" d="M10.1898 5.43407C10.3377 5.43407 10.4577 5.31321 10.4577 5.16413C10.4577 5.01505 10.3377 4.8942 10.1898 4.8942C10.0418 4.8942 9.92188 5.01505 9.92188 5.16413C9.92188 5.31321 10.0418 5.43407 10.1898 5.43407Z" fill="url(#paint32_radial_460_3514)" />
      <path opacity="0.25" d="M17.0628 5.31548C17.2108 5.31548 17.3307 5.19462 17.3307 5.04554C17.3307 4.89646 17.2108 4.7756 17.0628 4.7756C16.9149 4.7756 16.7949 4.89646 16.7949 5.04554C16.7949 5.19462 16.9149 5.31548 17.0628 5.31548Z" fill="url(#paint33_radial_460_3514)" />
      <path opacity="0.25" d="M21.8988 11.7939C22.0467 11.7939 22.1667 11.673 22.1667 11.524C22.1667 11.3749 22.0467 11.254 21.8988 11.254C21.7508 11.254 21.6309 11.3749 21.6309 11.524C21.6309 11.673 21.7508 11.7939 21.8988 11.7939Z" fill="url(#paint34_radial_460_3514)" />
      <path opacity="0.25" d="M5.13118 15.6915C5.27913 15.6915 5.39907 15.5707 5.39907 15.4216C5.39907 15.2725 5.27913 15.1517 5.13118 15.1517C4.98322 15.1517 4.86328 15.2725 4.86328 15.4216C4.86328 15.5707 4.98322 15.6915 5.13118 15.6915Z" fill="url(#paint35_radial_460_3514)" />
      <path opacity="0.25" d="M17.8665 21.2548C18.0145 21.2548 18.1344 21.134 18.1344 20.9849C18.1344 20.8358 18.0145 20.715 17.8665 20.715C17.7186 20.715 17.5986 20.8358 17.5986 20.9849C17.5986 21.134 17.7186 21.2548 17.8665 21.2548Z" fill="url(#paint36_radial_460_3514)" />
      <path opacity="0.25" d="M17.0501 14.6844C17.1981 14.6844 17.318 14.5635 17.318 14.4145C17.318 14.2654 17.1981 14.1445 17.0501 14.1445C16.9022 14.1445 16.7822 14.2654 16.7822 14.4145C16.7822 14.5635 16.9022 14.6844 17.0501 14.6844Z" fill="url(#paint37_radial_460_3514)" />
      <path opacity="0.25" d="M14.3778 5.57219C14.3517 5.14425 14.3124 4.69655 14.2602 4.2291C12.6136 5.26934 11.9863 6.53343 11.9863 6.53343L12.4764 7.60001C12.9926 6.61244 13.8485 5.92772 14.3778 5.57219Z" fill="url(#paint38_radial_460_3514)" />
    </g>
    <path d="M4.52429 9.57539C4.86406 10.563 4.0081 10.8197 4.28906 11.4584L5.24303 11.2082C4.96207 10.5695 5.70695 10.3259 5.40639 9.30545L4.52429 9.57539Z" fill="url(#paint39_linear_460_3514)" />
    <path d="M20.8132 6.42836C19.5063 7.17234 19.1731 5.6383 18.291 5.98066L18.5654 7.30401C19.441 6.96824 19.8134 8.40351 21.1203 7.65954L20.8132 6.42836Z" fill="url(#paint40_linear_460_3514)" />
    <path d="M21.2972 4.92036C21.4451 4.92036 21.5651 4.79951 21.5651 4.65043C21.5651 4.50135 21.4451 4.38049 21.2972 4.38049C21.1492 4.38049 21.0293 4.50135 21.0293 4.65043C21.0293 4.79951 21.1492 4.92036 21.2972 4.92036Z" fill="url(#paint41_linear_460_3514)" />
    <path d="M10.1898 5.0392C10.3377 5.0392 10.4577 4.91835 10.4577 4.76927C10.4577 4.62018 10.3377 4.49933 10.1898 4.49933C10.0418 4.49933 9.92188 4.62018 9.92188 4.76927C9.92188 4.91835 10.0418 5.0392 10.1898 5.0392Z" fill="url(#paint42_linear_460_3514)" />
    <path d="M17.0628 4.92036C17.2108 4.92036 17.3307 4.79951 17.3307 4.65043C17.3307 4.50135 17.2108 4.38049 17.0628 4.38049C16.9149 4.38049 16.7949 4.50135 16.7949 4.65043C16.7949 4.79951 16.9149 4.92036 17.0628 4.92036Z" fill="url(#paint43_linear_460_3514)" />
    <path d="M21.8988 11.399C22.0467 11.399 22.1667 11.2782 22.1667 11.1291C22.1667 10.98 22.0467 10.8592 21.8988 10.8592C21.7508 10.8592 21.6309 10.98 21.6309 11.1291C21.6309 11.2782 21.7508 11.399 21.8988 11.399Z" fill="url(#paint44_linear_460_3514)" />
    <path d="M5.07258 15.3823C5.22054 15.3823 5.34048 15.2614 5.34048 15.1123C5.34048 14.9633 5.22054 14.8424 5.07258 14.8424C4.92463 14.8424 4.80469 14.9633 4.80469 15.1123C4.80469 15.2614 4.92463 15.3823 5.07258 15.3823Z" fill="url(#paint45_linear_460_3514)" />
    <path d="M17.0179 14.3159C17.1658 14.3159 17.2858 14.195 17.2858 14.0459C17.2858 13.8969 17.1658 13.776 17.0179 13.776C16.8699 13.776 16.75 13.8969 16.75 14.0459C16.75 14.195 16.8699 14.3159 17.0179 14.3159Z" fill="url(#paint46_linear_460_3514)" />
    <path d="M2.94954 19.2403C3.09749 19.2403 3.21743 19.1195 3.21743 18.9704C3.21743 18.8213 3.09749 18.7004 2.94954 18.7004C2.80158 18.7004 2.68164 18.8213 2.68164 18.9704C2.68164 19.1195 2.80158 19.2403 2.94954 19.2403Z" fill="url(#paint47_linear_460_3514)" />
    <path d="M14.5145 4.63082C14.4884 4.20287 14.4492 3.75517 14.3969 3.28772C12.7503 4.32796 12.123 5.59205 12.123 5.59205L12.6131 6.65863C13.1293 5.67764 13.9852 4.98634 14.5145 4.63082Z" fill="url(#paint48_linear_460_3514)" />
    <path d="M9.35175 17.3242C9.50203 20.2474 7.13018 21.0045 6.41797 21.1691C6.79041 21.472 7.18245 21.7485 7.60063 21.9987C8.89437 21.4522 11.0245 20.0762 10.3057 17.0411C9.95288 17.1333 9.63271 17.2254 9.35175 17.3242Z" fill="url(#paint49_radial_460_3514)" />
    <path d="M8.67345 16.8239C8.84986 20.2606 5.53711 20.7083 5.53711 20.7083L6.00103 21.8078C6.00103 21.8078 10.6337 20.8137 9.62741 16.5474C9.27458 16.633 8.95441 16.7251 8.67345 16.8239Z" fill="url(#paint50_linear_460_3514)" />
    <defs>
      <radialGradient id="paint0_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(10.9194 8.69541) scale(12.6119 12.708)">
        <stop stopColor="#FFE030" />
        <stop offset={1} stopColor="#FFB92E" />
      </radialGradient>
      <radialGradient id="paint1_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(10.9194 8.69541) scale(9.92238 9.99797)">
        <stop stopColor="#FFEA5F" />
        <stop offset={1} stopColor="#FFBC47" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint2_linear_460_3514" x1="8.41724" y1="9.52514" x2="8.56595" y2="8.42532" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#7A4400" />
      </linearGradient>
      <linearGradient id="paint3_linear_460_3514" x1="17.5374" y1="9.52483" x2="17.3887" y2="8.425" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#7A4400" />
      </linearGradient>
      <radialGradient id="paint4_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(5.12611 6.14977) rotate(-45.7521) scale(8.0528 3.30779)">
        <stop stopColor="#EB672A" stopOpacity="0.5" />
        <stop offset="0.3025" stopColor="#E36521" stopOpacity="0.3422" />
        <stop offset="0.7868" stopColor="#CC600A" stopOpacity="0.0897045" />
        <stop offset="0.9589" stopColor="#C25E00" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint5_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.27637 15.5852) rotate(-2.79137) scale(4.39748 3.50588)">
        <stop stopColor="#FF4C00" />
        <stop offset="0.1995" stopColor="#F94C0E" stopOpacity="0.8005" />
        <stop offset="0.7145" stopColor="#EC4A2E" stopOpacity="0.2855" />
        <stop offset={1} stopColor="#E74A3A" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint6_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(18.5754 18.8391) rotate(-0.404649) scale(6.99176 1.59922)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint7_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(11.6323 18.4053) rotate(4.58851) scale(2.46896 1.75884)">
        <stop stopColor="white" stopOpacity="0.75" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint8_linear_460_3514" x1="12.5236" y1="18.3029" x2="13.8592" y2="18.3029" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#512D00" />
      </linearGradient>
      <linearGradient id="paint9_linear_460_3514" x1="18.0797" y1="16.9565" x2="18.5468" y2="20.1383" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF40BF" />
        <stop offset="0.8932" stopColor="#7000A3" />
      </linearGradient>
      <linearGradient id="paint10_linear_460_3514" x1="14.199" y1="16.6495" x2="14.5193" y2="18.8309" gradientUnits="userSpaceOnUse">
        <stop offset="0.2544" stopColor="#D1F2FF" />
        <stop offset={1} stopColor="#17BBFE" />
      </linearGradient>
      <linearGradient id="paint11_linear_460_3514" x1="18.8759" y1="17.106" x2="23.8615" y2="14.9823" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7000A3" stopOpacity={0} />
        <stop offset="0.2242" stopColor="#530078" stopOpacity="0.2242" />
        <stop offset="0.5925" stopColor="#260038" stopOpacity="0.5925" />
        <stop offset="0.8613" stopColor="#0B000F" stopOpacity="0.8613" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient id="paint12_linear_460_3514" x1="23.1546" y1="20.4537" x2="21.6242" y2="15.1216" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.1488" stopColor="#27002D" />
        <stop offset="0.4631" stopColor="#760086" />
        <stop offset="0.7193" stopColor="#B000C7" />
        <stop offset="0.9045" stopColor="#D300F0" />
        <stop offset={1} stopColor="#E100FF" />
      </linearGradient>
      <linearGradient id="paint13_linear_460_3514" x1="21.6299" y1="12.1453" x2="21.1274" y2="17.0495" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="0.1488" stopColor="#27002D" />
        <stop offset="0.4631" stopColor="#760086" />
        <stop offset="0.7193" stopColor="#B000C7" />
        <stop offset="0.9045" stopColor="#D300F0" />
        <stop offset={1} stopColor="#E100FF" />
      </linearGradient>
      <linearGradient id="paint14_linear_460_3514" x1="21.517" y1="16.0206" x2="21.3058" y2="14.582" gradientUnits="userSpaceOnUse">
        <stop stopColor="#7000A3" />
        <stop offset="0.4469" stopColor="#A900D2" />
        <stop offset="0.8117" stopColor="#D200F2" />
        <stop offset={1} stopColor="#E100FF" />
      </linearGradient>
      <linearGradient id="paint15_linear_460_3514" x1="11.6845" y1="18.0778" x2="12.8014" y2="18.2259" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#512D00" />
      </linearGradient>
      <radialGradient id="paint16_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(17.3277 17.14) rotate(-8.34916) scale(3.78145 0.424779)">
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint17_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(23.2887 14.2) rotate(77.8076) scale(1.91637 0.425908)">
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint18_linear_460_3514" x1="6.68104" y1="1.82361" x2="0.594134" y2="6.06177" gradientUnits="userSpaceOnUse">
        <stop offset="0.2544" stopColor="#D1F2FF" />
        <stop offset={1} stopColor="#17BBFE" />
      </linearGradient>
      <linearGradient id="paint19_linear_460_3514" x1="7.20551" y1="3.50832" x2="1.88176" y2="7.92546" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF40BF" />
        <stop offset="0.7456" stopColor="#7000A3" />
      </linearGradient>
      <linearGradient id="paint20_linear_460_3514" x1="4.64954" y1="2.06833" x2="0.946167" y2="5.14105" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF40BF" />
        <stop offset="0.7456" stopColor="#7000A3" />
      </linearGradient>
      <linearGradient id="paint21_linear_460_3514" x1="2.48205" y1="0.965265" x2="0.14662" y2="2.90299" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF40BF" />
        <stop offset="0.7456" stopColor="#7000A3" />
      </linearGradient>
      <radialGradient id="paint22_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(2.06604 4.97388) rotate(65.4668) scale(4.36569 1.06987)">
        <stop stopColor="#440063" stopOpacity="0.75" />
        <stop offset={1} stopColor="#420061" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint23_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(3.96637 2.98454) rotate(31.3948) scale(4.34722 1.07454)">
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint24_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(5.36999 8.6542) rotate(30.3898) scale(5.36379 5.48172)">
        <stop stopColor="#440063" stopOpacity="0.75" />
        <stop offset={1} stopColor="#420061" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint25_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.95819 12.1978) rotate(175.44) scale(1.61296 1.15233)">
        <stop offset="0.00132565" stopColor="#7A4400" />
        <stop offset={1} stopColor="#643800" />
      </radialGradient>
      <linearGradient id="paint26_linear_460_3514" x1="9.11528" y1="10.1522" x2="8.9853" y2="11.9209" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#512D00" />
      </linearGradient>
      <radialGradient id="paint27_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(16.9938 12.1978) rotate(4.55991) scale(1.61296 1.15233)">
        <stop offset="0.00132565" stopColor="#7A4400" />
        <stop offset={1} stopColor="#643800" />
      </radialGradient>
      <linearGradient id="paint28_linear_460_3514" x1="16.8369" y1="10.1522" x2="16.9669" y2="11.9209" gradientUnits="userSpaceOnUse">
        <stop offset="0.00132565" stopColor="#3C2200" />
        <stop offset={1} stopColor="#512D00" />
      </linearGradient>
      <linearGradient id="paint29_linear_460_3514" x1="14.8414" y1="17.3749" x2="15.1052" y2="19.3755" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF40BF" />
        <stop offset="0.8932" stopColor="#7000A3" />
      </linearGradient>
      <radialGradient id="paint30_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.87577 6.08105) scale(23.5363 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint31_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(6.20643 3.86516) rotate(0.0423128) scale(32.0234 32.2459)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint32_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.98722 5.08519) scale(23.5363 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint33_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.98645 5.08511) scale(23.5363 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint34_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.98716 5.08507) scale(23.5364 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint35_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(6.43118 5.44721) scale(23.5363 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint36_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(8.98643 5.08503) scale(23.5364 23.7156)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint37_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(9.83625 4.01544) scale(23.5363 23.7155)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <radialGradient id="paint38_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(7.54483 8.58957) rotate(-28.7515) scale(32.6342 32.7411)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint39_linear_460_3514" x1="5.21431" y1="10.9938" x2="4.45346" y2="9.7345" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9214" />
        <stop offset={1} stopColor="#FF4E0D" />
      </linearGradient>
      <linearGradient id="paint40_linear_460_3514" x1="22.7165" y1="7.5229" x2="18.348" y2="6.70796" gradientUnits="userSpaceOnUse">
        <stop stopColor="#80DAFE" />
        <stop offset={1} stopColor="#008EE6" />
      </linearGradient>
      <linearGradient id="paint41_linear_460_3514" x1="19.7234" y1="4.34852" x2="22.8258" y2="4.95499" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCB4B" />
        <stop offset={1} stopColor="#E81CA2" />
      </linearGradient>
      <linearGradient id="paint42_linear_460_3514" x1="11.7536" y1="4.73511" x2="8.53206" y2="4.81368" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FBE07A" />
        <stop offset={1} stopColor="#E81CA2" />
      </linearGradient>
      <linearGradient id="paint43_linear_460_3514" x1="16.9563" y1="3.39847" x2="17.0669" y2="4.71509" gradientUnits="userSpaceOnUse">
        <stop stopColor="#80DAFE" />
        <stop offset={1} stopColor="#008EE6" />
      </linearGradient>
      <linearGradient id="paint44_linear_460_3514" x1="22.3452" y1="11.6204" x2="21.2526" y2="10.4436" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFCB4B" />
        <stop offset={1} stopColor="#E81CA2" />
      </linearGradient>
      <linearGradient id="paint45_linear_460_3514" x1="4.52891" y1="15.0198" x2="5.2908" y2="15.1621" gradientUnits="userSpaceOnUse">
        <stop offset="0.2544" stopColor="#9F00E8" />
        <stop offset={1} stopColor="#7000A3" />
      </linearGradient>
      <linearGradient id="paint46_linear_460_3514" x1="16.1616" y1="15.1777" x2="17.6349" y2="13.2572" gradientUnits="userSpaceOnUse">
        <stop stopColor="#80DAFE" />
        <stop offset={1} stopColor="#008EE6" />
      </linearGradient>
      <linearGradient id="paint47_linear_460_3514" x1="2.57414" y1="18.8499" x2="4.32318" y2="19.4713" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9214" />
        <stop offset={1} stopColor="#FF4E0D" />
      </linearGradient>
      <linearGradient id="paint48_linear_460_3514" x1="18.4075" y1="1.54097" x2="11.4192" y2="6.38808" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FBE07A" />
        <stop offset={1} stopColor="#E81CA2" />
      </linearGradient>
      <radialGradient id="paint49_radial_460_3514" cx={0} cy={0} r={1} gradientUnits="userSpaceOnUse" gradientTransform="translate(2.00202 16.5854) scale(11.1158 11.2005)">
        <stop stopColor="#7A4400" stopOpacity="0.5" />
        <stop offset="0.5833" stopColor="#894D00" stopOpacity="0.2069" />
        <stop offset="0.995" stopColor="#975500" stopOpacity={0} />
      </radialGradient>
      <linearGradient id="paint50_linear_460_3514" x1="8.81468" y1="14.7273" x2="5.93848" y2="26.2864" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F78174" />
        <stop offset="0.3893" stopColor="#8800C7" />
        <stop offset={1} stopColor="#3640FF" />
      </linearGradient>
    </defs>
  </svg>
)

export default Cheer
